import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Theme from '../../App.js';
import DescriptionDialog from '../Dialog/DescriptionDialog.js'
import {DateFormatInput, TimeFormatInput} from 'material-ui-next-pickers'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Radio } from 'antd';
import { borders } from '@material-ui/system';
import Select from '@material-ui/core/Select';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const formatDate = 'DD/MM/YYYY';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  checkbox: {
    color: 'rgb(120,195,17)',
    '&$checked': {
      color: 'rgb(120,195,17)',
    },
  },
  checked: {},
});

class CreditCard extends React.Component {
  state = {
    value: 0,
    checked: false,
    methodTypeScreen: 0,
    numOfPayments: 1,
    sum: '',
    cardNumber: '',
    cardType: '',
    currencyType: 'ILS',
    dealType: 1,
    date_payment: '',
    voucherCode: '',
    confirmationCode: '',
    date_payment_display: new Date()
  };

  componentDidMount() {
    this.updateDate()
  }

  updateDate = () => {
    var dateObj = this.state.date_payment_display
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    this.setState({date_payment: newDate}, () => {
      console.log(this.state.date_payment);
    })
  }

  handlePaymentDateChange = date => {
    var dateObj = new Date(date);
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    this.setState({date_payment: newDate}, () => {
      console.log(this.state.date_payment);
    })
    this.setState({date_payment_display: date}, () => {
      console.log(this.state.date_payment_display)
    })
  };

  handleChangeText = name => event => {
    this.setState({ [name]: String(event.target.value) });
  };

  handleChange = (event, value) => {
    this.props.main.setState({documentType: value})
    this.setState({ value });
  };

  onChangeCurrency = (evnet, value) => {
    this.setState({currency: value});
  }

  onChangeLanguage = (evnet, value) => {
    this.setState({language: value});
  }

  onChangeMethodType = (event, value) => {
    this.setState({method: value});
  }

  returnTabStyle = (index) => {
    if (this.state.value != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '25%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '25%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)", marginLeft: 2
      }
    }
  }
  handleDateChange = date => {
    this.setState({ date: date });
  };

  getDataToPresent = () => {
    var cardType = this.state.cardType
    var dataToPresent = ''
    if (this.state.dealType == 1) {
      dataToPresent = 'עסקה רגילה' + ' / '
    } else if (this.state.dealType == 2) {
      dataToPresent = this.state.numOfPayments + ' ' + 'תשלומים' + ' / '
    } else if (this.state.dealType == 3) {
      dataToPresent = this.state.numOfPayments + ' ' + 'תשלומי קרדיט' + ' / '
    }
    if (cardType == "1") {
      dataToPresent = dataToPresent + "ישראכרט"
    } else if (cardType == "2") {
      dataToPresent = dataToPresent + "ויזה"
    } else if (cardType == "3") {
      dataToPresent = dataToPresent + "מאסטרקארד"
    } else if (cardType == "4") {
      dataToPresent = dataToPresent + "אמריקן אקספרס"
    } else if (cardType == "5") {
      dataToPresent = dataToPresent + "דיינרס"
    }
    dataToPresent = dataToPresent + ' ' + this.state.cardNumber + ' '
    return dataToPresent
  }

  saveLine = () => {
    if (this.state.numOfPayments == "" || this.state.sum == "" || this.state.cardNumber == "" || this.state.cardType == "" || this.state.currencyType == "" || this.state.dealType == "" || this.state.date_payment == "") {
      alert('מלא את כל פרטי התשלום')
    } else {
      var data = {
        type: 'credit_card',
        payments: parseInt(this.state.numOfPayments),
        sum: parseFloat(this.state.sum).toFixed(2),
        cardNumber: this.state.cardNumber,
        cardType: this.state.cardType,
        currencyType: this.state.currencyType,
        date_payment: this.state.date_payment,
        dealType: parseInt(this.state.dealType),
        voucherCode: this.state.voucherCode,
        confirmationCode: this.state.confirmationCode,
        dataToPresent: this.getDataToPresent()
      }
      console.log(data);
      this.setState({numOfPayments: 1, sum: '', cardNumber: '', cardType: '', currencyType: 'ILS', date_payment_display: new Date(), dealType: 1})
      this.updateDate()
      this.props.addNewLineOfPayment(data)
    }
  };

  handleChangeSelect = name => event => {
    console.log(name);
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root} style={{padding: 10, border: '0px solid black', borderRadius: '25px', marginTop: 12, direction: 'rtl'}}>
        <DescriptionDialog open={this.state.openAlert}  closeAlert={this.handleCloseAlert} text={this.state.alertText}/>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={1} sm={1} md={1}>
            <Button onClick={() => this.saveLine()} elevation={0} style={{marginTop: 25, textTransform: 'none', height: 40, width: '90%', borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>שמור</b>
            </Button>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>ואוצ׳ר</div>
            <br />
            <input type={"text"} className="CustomInput" onChange={this.handleChangeText("voucherCode")} value={this.state.voucherCode} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>מס׳ אישור</div>
            <br />
            <input type={"text"} className="CustomInput" onChange={this.handleChangeText("confirmationCode")} value={this.state.confirmationCode} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>תאריך</div>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                InputProps={{
                 disableUnderline: true,
                }}
                style={{float: 'right', width: '100%',
                height: 40,
                padding: 10,
                color: 'rgb(90,88,110)',
                fontSize: '16px',
                textAlign: 'left',
                borderRadius: '20px',
                border: '1px solid rgb(225,225,225)',
                outline: 'none',
                margin: 5}}
                margin="normal"
                value={this.state.date_payment_display}
                onChange={this.handlePaymentDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <div style={{direction: 'rtl', textAlign: 'right', marginTop: 5, fontFamily: 'arial'}}>מטבע</div>
            <Select
              native
              value={this.state.currencyType}
              onChange={this.handleChangeSelect('currencyType')}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
              className="CustomInput"
                  disableUnderline
            >
              <option value="" />
              <option value={"ILS"}>₪</option>
              <option value={"USD"}>$</option>
            </Select>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>סכום</div>
              <br />
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("sum")} value={this.state.sum} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>מס׳ כרטיס</div>
              <br />
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("cardNumber")} value={this.state.cardNumber} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <div style={{direction: 'rtl', textAlign: 'right', marginTop: 0, fontFamily: 'arial'}}>סוג כרטיס</div>
              <Select
                native
                value={this.state.cardType}
                onChange={this.handleChangeSelect('cardType')}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                className="CustomInput"
                    disableUnderline
              >
                <option value="" />
                <option value={"1"}>ישראכרט</option>
                <option value={"2"}>ויזה</option>
                <option value={"3"}>מאסטרקארד</option>
                <option value={"4"}>אמריקן אקספרס</option>
                <option value={"5"}>דיינרס</option>
              </Select>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <div style={{direction: 'rtl', textAlign: 'right', marginTop: 0, fontFamily: 'arial'}}>סוג עסקה</div>
              <Select
                native
                value={this.state.dealType}
                onChange={this.handleChangeSelect('dealType')}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                className="CustomInput"
                    disableUnderline
              >
                <option value="" />
                <option value={1}>רגיל</option>
                <option value={2}>תשלומים</option>
                <option value={3}>קרדיט</option>
              </Select>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 0, fontFamily: 'arial'}}>תשלומים</div>
              <br />
              <input type={"number"} value={this.state.numOfPayments} className="CustomInput" onChange={this.handleChangeText("numOfPayments")} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}} required/>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
            </Grid>
          </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(CreditCard);
